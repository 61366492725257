.AdminRecentUserCardBody {
    border-radius: 16px;
    background: rgba(255, 255, 255, 1);
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  }
.AdminRecentUser {
    background-color: rgb(242, 242, 242);
}
.AdminRecentUserActivtyScroll {
    height: 350px;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    overflow-y: scroll ;
}
.AdminRecentUserActivtyScroll::-webkit-scrollbar {
    display: none;
}
/* edited */
.AdminRecentUserActivtyScrolls {
    height: 450px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll ;
}

.AdminRecentUserCardBodys {
    height: 500px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 1);
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .relatives{
    position: relative;
  }

  .view.open {
    display: block;
    transition: 0.5s ease;
  
  }
  .view {

    display: none;
    position: absolute;
    /* top: 236px; */
    z-index: 1000;
    margin-top: 50px;
    width: 400px;
  }
  .upbox{
position: relative;
top: -70px;
 left:80px;
 display: flex;
 gap: 50px;
 padding: 30px;
 }

 
.marl{
  margin-left: 0px;
}

@media(max-width:1200px) {
  .upbox{
    position: relative;
    top: -70px;
     left:30px;
     }
}
@media(max-width:1100px) {
  .marl{
    margin-left: 31px;
  }
  .upbox{
    position: relative;
    top: -70px;
     left:20px;
     }
}
@media(max-width:660px) {
  .marl{
    margin-left: 11px;
  }
  .upbox{
    position: relative;
    top: -40px;
     left:20px;
     }
}
  @media(max-width:550px) {
    .view {
        
        margin-top: 50px;
        width: 320px;
      }
      .marl{
        margin-left: 11px;
      }
  }