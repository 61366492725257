/* Custom styles for the login page */
body {
  background-color: #f8f9fa;
}

a {
  color: #28a745;
}

a:hover {
  text-decoration: none;
}
.inputField[type="email"],
.inputField[type="password"],
.inputField[type="text"] {
  height: 55px;
  padding: 8px 16px 8px 16px;
  gap: 0px;
  border-radius: 16px;
  border: 1px solid rgba(51, 51, 51, 1);
  background: transparent;
}
.inputSeleteField {
  height: 55px;
  padding: 8px 16px 8px 16px;
  gap: 0px;
  border-radius: 16px;
  border: 1px solid rgba(51, 51, 51, 1);
}

.inputField > div > input {
  border: 1px solid rgba(51, 51, 51, 1);
}
.linkText {
  color: rgba(115, 115, 115, 1);
}
.button {
  background-color: rgba(0, 110, 74, 1);
  height: 55px;
}
.LogoutComfirmText {
font-family: Open Sans;
font-size: 16px;
font-weight: 700;
line-height: 25.6px;
color: rgba(160, 160, 160, 1);
}

.select-placeholder {
  /* color: #6c757d;  */
  /* Placeholder color */
}

.select-placeholder:invalid {
  /* color: #6c757d;     */
  /* Ensure the color is applied when invalid */
}


.bgmodes{
 
width: 100%;
 background:linear-gradient(#F2F2F2E5, #F2F2F2E5), url("../../../assets/bg/c5e7fbb8a86b53a3d54af05c22aa2a5f.jpg");
background-position: center;
background-size: cover;
padding-top: 20px;
padding-bottom: 20px;

}