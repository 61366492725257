.wareHouserCategoryText {
font-family: Open Sans;
font-size: 12px;
font-weight: 400;
line-height: 19.2px;
text-align: left;
}


.tablesSize{
    width:120px;
height: 68px;
padding: 8px 16px 8px 16px;
gap: 0px;
 border: 1px solid #FFFFFF
}
 
.tablesSize tbody tr td:nth-child(even) {
    background-color:  #f2f2f2; /* White background for all rows */
    font-weight: 400;
    padding: 8px 16px 8px 16px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    /* line-height: 25.6px; */
    color: #292929;
    
  }

  .tablesSize tbody tr td:nth-child(odd) {
    font-size: 13px;
    /* line-height: 25.6px; */
    color: #292929;
     font-family: 'Open Sans', sans-serif;
     padding: 8px 16px 8px 16px;
     font-weight: 400;
    background-color: #ffffff; /* Light gray background for even rows */
  }

  /* NonAvaliable.css */
.nonAvailableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
