.DiscrepancyDeleteButton {
  background-color: transparent;
  border: 1px solid rgba(194, 194, 194, 1);
  color: rgba(194, 194, 194, 1);
  margin-bottom: 10px;
}
.ListRow {
  height: 72px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.BadgeList {
  width: auto;
  height: auto;
  background: rgba(242, 242, 242, 1);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
}
.ListButton {
  width: 121px;
  height: 42px;
  border-radius: 16px;
  border: none;
  background-color: rgba(106, 90, 44, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}
.ListRow > div > div > input {
  border: 2px solid rgba(51, 51, 51, 1);
}

.DiscrepancyInput {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(160, 160, 160, 1);
  border-radius: 16px;
  height: 55px;
}
.DiscrepancyInput[type='text'] {
  color: rgba(77, 77, 77, 1);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
}
.DiscrepancyTextArea {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(160, 160, 160, 1);
  border-radius: 16px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: rgba(77, 77, 77, 1);
}

