.formBarContainer {
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(160, 160, 160, 1);
  display: flex;
  gap: 10px;
  padding-left: 10px;
  align-items: center;
}
.settingTexts,
.DiscrepancyInput,
.DiscrepancyInput > option {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: rgba(77, 77, 77, 1) !important;
}
.settingEmail {
    height: 55px;
}

