.seachSideBar {
    width: 228px;
    height: 58px;
    margin-left: 10px;
    margin-top: 20px;
  }
  .sideNavSearchBarContainer {
    border-radius: 16px;
    background-color: rgba(224, 224, 224, 1);
    display: flex;
    gap: 10px;
    padding-left: 10px;
    align-items: center;
  }
  .sideNavSearchBarContainer:hover {
    border: 2px solid rgba(160, 160, 160, 1);
  }
  
  .sideNavSearchIcon {
    margin: 10px;
  }
  
  .sideNavSearchBar[type="text"] {
    width: 100%;
    height: 58px;
    outline: none;
    border: none;
    background: transparent;
  }