.ComfirmationContainer {
  width: auto;
  height: auto;
  /* position: absolute;
  top: 80px;
  left: 750px; */
  justify-content: center;
  z-index: 1000px;
  border-radius: 8px;
  align-content: center;
}
.ComfirmationContentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px;
  padding: 5px 15px 5px 15px;
}
.ComfirmationContentWrapper i {
  color: rgba(250, 250, 250, 1);
}
.ComfirmationMessage {
  padding: 0px;
  margin: 0px;
  gap: 0px;
  font-family: Gotham;
  font-size: 19px;
  font-weight: 400;
  line-height: 19.2px;
  color: rgba(250, 250, 250, 1);
}
