.pushNotificationAttachFile,
.pushNotificationAttachFile:hover {
  border: 1px dashed rgba(160, 160, 160, 1);
  display: flex;
  align-items: center;
}
.pushNotificationTitle {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(160, 160, 160, 1);
  border-radius: 16px;
  height: 55px;
}
.pushNotificationTitle::placeholder {
  color: rgba(160, 160, 160, 1);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
}
.pushNotificationTextArea {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(160, 160, 160, 1);
  border-radius: 16px;
  height: 411px;
}
.pushNotificationTextArea::placeholder {
  color: rgba(160, 160, 160, 1);
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
}
