.itemHeaderText {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: rgba(77, 77, 77, 1);
}
.itemDetailText {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  text-align: left;
  color: rgba(51, 51, 51, 1);
}
.itemDetailMainRow {
  border-bottom: 2px solid rgba(0, 76, 46, 1);
}
.itemDetailEditButton,
.itemDetailEditButton:hover {
  background-color: rgb(106, 90, 44);
  border: none;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 25.6px;
  color: antiquewhite;
}

.itemDetailPrintButton,
.itemDetailPrintButton:hover {
    border: 2px solid rgb(106, 90, 44);
    color: rgb(106, 90, 44);
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.6px;
    background-color: transparent;
}

/* Inventory.css */

/* Ensure the pagination container is centered and has margin from content above */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Style the pagination buttons */
.pagination button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff; /* Bootstrap primary color */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #6c757d; /* Bootstrap secondary color for disabled state */
  cursor: not-allowed;
}

/* Add spacing between the page number text and buttons */
.pagination span {
  font-size: 16px;
}

