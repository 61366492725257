.WareHouseGenerateInventoryButton,
.WareHouseGenerateInventoryButton:hover {
  width: 190px;
  height: 48px;
  padding: 8px 16px 8px 16px;
  border-radius: 16px;
  background-color: rgba(106, 90, 44, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
  margin: 0px 0px 5px 0px;
}  


